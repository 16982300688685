<template>
  <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="500px"

    >
      <v-card>
        <v-card-text style="height: 370px" class>
          <div class="ma-6 pa-0 text-center">
            <img
              src="@/assets/img/restore.png"
              width="140px"
              height="140px"
              alt="One Box"
            />
          </div>
          <div class="text-center">
            <h2>{{ $t("text_want_restore") }}</h2>
            <!-- <h2>คุณต้องการกู้คืนเอกสารที่ Filter ใช่หรือไม่ ?</h2> -->
          </div>
          <div class="text-center mb-5">
            <br />
            <v-chip v-if="checkrecovery === false">
            <span style="font-size: 20px;">
              {{ $t("Number_documents") }} {{totalfile}} {{ $t("items")}}
                <!-- เช็คประเภทการลบ มาจากหน้าไหน -->
                <!-- {{type}} -->
            </span>
            </v-chip>
            <v-chip v-else>
            <span style="font-size: 20px;">{{ $t("multirecovery.document_recovering") }} : {{listdata}} / {{totalfile}}</span>
            </v-chip>
          </div>
          <br />
          <div class="text-center">

          <!-- <v-btn color="red" outlined @click="$emit('closedialog')">{{
            $t("changeName.close")
          }}</v-btn> -->
          <v-btn 
              class="mr-5" 
              color="red" 
              outlined 
              @click="$emit('closedialog')" 
          >
              {{$t('checkdelete.cancel')}}
          </v-btn>
          <v-btn 
              color="#479F31" 
              dark 
              @click="type_()"
              :loading="loaddataprogress"
          >
              {{$t('trashRClick.restore')}}
          </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
  export default {
    data () {
      return {
        dialog: false,
        loaddataprogress: false,
        listdata: 0,
        checkrecovery: false,
        // file_data: [],
        // total_file:0,
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color","role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
            this.checkrecovery = false;
            this.listdata = 0;
            console.log("data",this.data);
            console.log("status",this.status);
        }
        return this.show;
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    }
    },
    props:["show","data","status","filedata","totalfile","type"],
    methods:{
      type_(){
        console.log("TYPEEE....Restore");
        if(this.type == "trashinbox"){
          console.log("trashinbox");
          this.restore_filter_inbox();
        }else if(this.type == "trashoutbox"){
          console.log("trashoutbox");
          this.restore_filter_outbox();
        }
      },
     async restore_filter_inbox(){
        console.log("restore_filter_inbox");
        console.log("filedata",this.filedata);
        if(this.totalfile == 0){
        Toast.fire({
            icon: "error",
            title: this.$t('dialogconfirmdelete.undocumented_restore'),
        });
        }else{
          this.checkrecovery = true;
        for (let i = 0; i < this.filedata.length; i++) {
          let payload = {
            business_id: this.dataAccountActive.business_info.business_id,
            data_type: "inbox",
            inbox_id: [this.filedata[i]["inbox_id"]],
          };
          console.log("payload", payload);
        
        // console.log("showdelete", this.showdelete);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.loaddataprogress = true;
              this.listdata = i+1;
              // Toast.fire({
              //   icon: "success",
              //   title: "OK",
              // });
              console.log("restore", response.length, response.data);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            if(i== this.filedata.length -1){
              this.loaddataprogress = false,
              this.$emit('closedialog')
              this.$emit('reload')

              Toast.fire({
                icon: "success",
                title: this.$t('dialogconfirmdelete.restoresuccess'),
              });
            }
          });
        }
      }
      },
      async restore_filter_outbox(){
        console.log("restore_filter_outbox");
        console.log("filedata",this.filedata);
        if(this.totalfile == 0){
        Toast.fire({
            icon: "error",
            title: this.$t('dialogconfirmdelete.undocumented_restore'),
        });
        }else{
          this.checkrecovery = true;
        for (let i = 0; i < this.filedata.length; i++) {
          let payload = {
            business_id: this.dataAccountActive.business_info.business_id,
            data_type: "outbox",
            inbox_id: [this.filedata[i]["inbox_id"]],
          };
          console.log("payload", payload);
        
        // console.log("showdelete", this.showdelete);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.loaddataprogress = true;
              this.listdata = i+1;
              // Toast.fire({
              //   icon: "success",
              //   title: "OK",
              // });
              console.log("restore", response.length, response.data);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            if(i== this.filedata.length -1){
              this.loaddataprogress = false,
              this.$emit('closedialog')
              this.$emit('reload')

              Toast.fire({
                icon: "success",
                title: this.$t('dialogconfirmdelete.restoresuccess'),
              });
            }
          });
        }
      }
        
      },
    }
  }
</script>
<style>

</style>